<template>
  <div>
    <StudioPlusSubscriptionHeader v-if="hideTitle === false" />
    <BaseCard noPadding>
      <div class="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row">
        <div class="tw-flex tw-pb-0 lg:tw-pb-8 tw-p-8 tw-flex-col lg:tw-flex-row">
          <p class="tw-font-bold tw-text-xl tw-text-center tw-mb-3.5 lg:tw-hidden">
            {{ $t('studio_plus_subscription_recap_header') }}
          </p>
          <div class="tw-min-h-[216px] tw-flex-basis-1/4 tw-flex tw-justify-end tw-mb-3.5 lg:tw-mb-0">
            <img
              :src="StudioPlusSubscriptionPurchased"
              alt="Studio Plus Subscription"
              class=""
              height="216"
              width="252"
            />
          </div>
          <div class="tw-flex-basis-3/4 lg:tw-ml-12 tw-mb-3.5 lg:tw-mb-0">
            <p class="tw-font-bold tw-text-xl tw-hidden lg:tw-flex tw-mb-6">
              {{ $t('studio_plus_subscription_recap_header') }}
            </p>
            <ul class="tw-mb-6">
              <li
                v-for="(item, index) in benefitsList"
                :key="index"
                class="tw-flex tw-items-center tw-py-1 first:tw-pt-0 last:tw-pb-0"
              >
                <BaseIcon :height="14" :width="14" icon-name="checkmark-blue" class="tw-text-gray-900 tw-min-w-[14px]">
                  <CheckmarkBlue />
                </BaseIcon>
                <span class="lg:tw-pl-2 tw-pl-4">{{ $t(item) }}</span>
              </li>
            </ul>

            <div class="tw-flex tw-flex-col md:tw-flex-row">
              <GhostButton size="medium" @click="redirectToRateInsights" class="md:tw-mr-4 md:tw-mb-0 tw-mb-4">{{
                $t('studio_plus_rate_insights_cta')
              }}</GhostButton>
            </div>
          </div>
        </div>

        <div class="lg:tw-hidden tw-px-8 tw-pb-2 tw-bg-gray-100" :class="showStatus ? '' : 'tw-rounded-b-lg'">
          <button class="tw-flex tw-items-center tw-justify-between tw-mt-3 tw-w-full" @click="toggleStatus()">
            {{ $t('studio_plus_subscription_status_header') }}
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              :icon-color="'#6C707A'"
              :class="showStatus ? 'tw-rotate-[270deg]' : 'tw-rotate-90'"
            >
              <ChevronRight />
            </BaseIcon>
          </button>
        </div>

        <div :class="getStyles" class="tw-p-8 tw-min-w-[300px] tw-flex-col tw-justify-center tw-bg-green-100">
          <div class="tw-mb-8">
            <p class="tw-font-bold tw-mb-4">{{ $t('studio_plus_subscription_status_header') }}{{ ': ' }}</p>
            <p class="tw-text-sm tw-flex tw-items-center">
              <span class="tw-inline-flex tw-h-3.5 tw-w-3.5 tw-bg-green-700 tw-mr-2 tw-rounded-full" />{{
                $t('business_studio_plus_active')
              }}
            </p>
          </div>

          <div v-if="studioPlusCampaign?.partnerId">
            <div class="tw-mb-4 tw-flex tw-items-center">
              <p class="tw-font-bold">
                {{ $t('connectivity_provider_header') }}
              </p>
              <UiTooltip tooltip-position="top" :label="$t('studio_plus_connectivity_tooltip')">
                <BaseIcon
                  :height="16"
                  :width="16"
                  icon-name="information-outline"
                  class="tw-fill-none tw-stroke-gray-900 tw-h-[16px] tw-w-[16px] tw-ml-1"
                >
                  <InformationOutline />
                </BaseIcon>
              </UiTooltip>
            </div>
            <img
              :src="`https://imgcy.trivago.com/e_trim/f_auto,dpr_2,bo_1px_solid_transparent,c_pad,h_34,q_auto,w_81/partnerlogos-s3/${studioPlusCampaign?.partnerId}`"
              alt="partner logo"
              height="30"
              class="tw-max-h-[30px] tw-mt-4"
            />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'

import ChevronRight from '@/components/icons/ChevronRight.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import CheckmarkBlue from '@/components/icons/CheckmarkBlue.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import StudioPlusSubscriptionHeader from '@/components/studioPlus/StudioPlusSubscriptionHeader.vue'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()

const StudioPlusSubscriptionPurchased = new URL(
  '/src//assets/img/studio-plus/studio-plus-subscription-purchased.svg',
  import.meta.url
)
const hideTitle = ref(true)
const showStatus = ref(false)

const { studioPlusCampaign } = useStudioPlusCampaign(selectedAccommodationId)

const toggleStatus = () => {
  showStatus.value = !showStatus.value
}

const redirectToRateInsights = () => {
  router.push({ name: 'rate-insights' })
  appAnalytics.track(appAnalytics.events.SP_VISIT_RI_FROM_BANNER, {
    item_id: selectedAccommodationId.value,
  })
}

const getStyles = computed(() => {
  let classes = []
  if (showStatus.value) {
    classes.push('tw-flex lg:tw-flex tw-rounded-b-lg shadow')
  } else {
    classes.push('tw-hidden lg:tw-flex tw-rounded-r-lg')
  }
  return classes.join(' ')
})

const benefitsList = [
  'studio_plus_subscription_recap_point_1',
  'studio_plus_subscription_recap_point_2',
  'studio_plus_subscription_recap_point_3',
  'studio_plus_subscription_recap_point_4',
]

onMounted(() => {
  const bannerInfo = window.localStorage?.getItem(`contact_info_banner_${selectedAccommodationId.value}`)
  if (bannerInfo === null) {
    hideTitle.value = false
  } else {
    hideTitle.value = JSON.parse(bannerInfo)
  }
  window.addEventListener('contact_info_banner_localstorage-changed', event => {
    hideTitle.value = event.detail.storage === null ? false : JSON.parse(event.detail.storage)
  })
})
</script>
<style scoped>
.shadow {
  box-shadow: 0px 2px 10px 0px #6c707a26;
  box-shadow: 0px 2px 4px 0px #3f57bd1a;
}
.arrow-dropdown {
  transform: rotate(90deg);
  pointer-events: none;
}
</style>
