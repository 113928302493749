<template>
  <div class="tw-mt-6" v-if="isPositive">
    <i18n-t keypath="cancelation_text" tag="p" scope="global" class="tw-mb-6">
      <template #EndDate>
        <b>{{ subscriptionEndDate }}</b>
      </template>
    </i18n-t>
  </div>
  <div class="tw-mt-6" v-if="!isPositive && retries < 3">
    <p class="tw-mb-6" v-html="$t('cancellation_fail_info')" />
    <p class="tw-mb-3">{{ $t('cancellation_fail_tip_header') }}</p>
    <ul class="tw-ml-6">
      <li
        class="tw-list-disc tw-py-1 first:tw-pt-0 last:tw-pb-0 tw-leading-5"
        v-for="(item, index) in solutions"
        :key="index"
      >
        {{ $t(item) }}
      </li>
    </ul>
    <p class="tw-mt-6" v-html="$t('cancellation_fail_help')" />
  </div>
  <div class="tw-mt-6" v-if="!isPositive && retries >= 3">
    <p v-html="$t('cancellation_fail_support')" />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  endDate: Date,
  isPositive: Boolean,
  retries: Number,
})

const solutions = ref(['cancellation_fail_tip_1', 'cancellation_fail_tip_2', 'cancellation_fail_tip_3'])

const subscriptionEndDate = computed(() => {
  if (props.endDate) {
    return new Date(props.endDate)?.toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  } else return ''
})
</script>
<style scoped>
p :deep(a) {
  text-decoration: underline;
}
</style>
