<template>
  <div>
    <StudioPlusReactivationModal
      v-if="isReactivationModalOpen || (reactivationCompleted && accommodationId === reactivationAccommodationId)"
      :isOpen="isReactivationModalOpen"
      :expired="subscription.status === 'expired'"
      :cancelled="subscription.status === 'canceled'"
      :accommodationId="{ value: accommodationId }"
      :accommodationName="accommodationName"
      source="settings"
      @closeModal="closeReactivationModal"
    />
    <div class="tw-grid tw-grid-cols-[1fr_1fr] lg:tw-grid-cols-[2fr_2fr_1fr_1fr_1fr_auto] w-gap-2 tw-items-center">
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscribed_property') }}</div>
        <div class="tw-text-sm tw-max-w-[250px] tw-text-ellipsis">{{ $t(accommodationName) }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_plan') }}</div>
        <div class="tw-text-sm">{{ $t(productName) }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_billing') }}</div>
        <p v-if="subscription.pricing_plan === 'MONTHLY'" class="tw-text-sm">
          {{ $t('subscription_billing_monthly') }}
        </p>
        <p v-if="subscription.pricing_plan === 'YEARLY'" class="tw-text-sm">
          {{ $t('subscription_billing_annually') }}
        </p>
      </div>
      <!-- Subscription Active -->
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2" v-if="subscription.status === 'active'">
        <div class="tw-text-sm tw-font-bold">
          {{ isReactivatedActive ? $t('business_studio_plus_reactivation_date') : $t('subscription_start') }}
        </div>
        <div class="tw-text-sm">{{ startDate }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2" v-if="subscription.status === 'active'">
        <div class="tw-text-sm tw-font-bold">
          {{
            isReactivatedActive
              ? $t('business_studio_plus_subscription_auto_renewal')
              : $t('business_studio_plus_subscription_payment_date')
          }}
        </div>
        <div class="tw-text-sm">{{ nextPaymentDate }}</div>
      </div>
      <!-- Subscription Cancelled -->
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2"
        v-if="subscription.status === 'canceled'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_start') }}</div>
        <div class="tw-text-sm">{{ startDate }}</div>
      </div>
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2"
        v-if="subscription.status === 'canceled'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscriptions.activeUntil') }}</div>
        <div class="tw-text-sm">{{ subscriptionEndDate }}</div>
      </div>

      <!-- Subscription Expired -->
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full tw-mr-2"
        v-if="subscription.status === 'expired'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_expiry_date') }}</div>
        <div class="tw-text-sm">{{ subscriptionEndDate }}</div>
      </div>

      <div class="tw-flex tw-flex-col md:tw-items-end tw-mt-4 md:tw-mt-0 tw-w-[170px]">
        <MainButton
          v-if="subscription.status !== 'active'"
          :disabled="subscription.hasActiveRC"
          size="medium"
          class="tw-w-full md:tw-w-auto"
          @click="openReactivationModal"
        >
          {{ $t('business_studio_plus_reactivate_cta') }}
        </MainButton>

        <button
          v-if="subscription.status === 'active'"
          class="tw-flex tw-font-bold tw-text-sm tw-text-gray-700 tw-items-end tw-grow tw-text-red-900"
          @click="() => toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
        >
          {{ $t('subscriptions.details.cancel.cta') }}
        </button>
      </div>
    </div>
  </div>
  <CancellationModal
    :isOpen="isCancellationOpen"
    :accommodationName="accommodationName"
    :accommodationId="accommodationId"
    :userDetails="userDetails"
    :partnerId="subscription.partnerId"
    :language="language"
    @closeModal="toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
  />
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { useUserDetails } from '@/layouts/queries'

import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'
import CancellationModal from '@/components/settings/subscriptions/cancellation/CancellationModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const router = useRouter()
const store = useStore()
const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})

const reactivationCompleted = ref(false)
const isCancellationOpen = ref(false)
const isReactivationModalOpen = ref(false)
const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const productName = computed(() => props.subscription?.product?.details?.[0]?.name)
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)
const language = computed(() => store.state.locale.language)
const startDate = computed(() =>
  new Date(props.subscription?.subscriptionStartDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
)

const subscriptionEndDate = computed(() => {
  return new Date(props.subscription?.endDate.replace('@', ' ')).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const nextPaymentDate = computed(() => {
  return new Date(props.subscription?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const isReactivatedActive = computed(() => {
  const startDate = new Date(props.subscription?.subscriptionStartDate)
  const currentDate = new Date()
  if (startDate !== undefined || startDate < currentDate) {
    return false
  } else {
    return true
  }
})

onMounted(() => {
  if (router.currentRoute?.value?.query?.reactivate === 'true') {
    reactivationCompleted.value = true
  } else {
    reactivationCompleted.value = false
  }
})

const toggleCancelSubscription = action => {
  if (action === 'open') {
    isCancellationOpen.value = true
  }
  if (action === 'close') {
    isCancellationOpen.value = false
  }
}

const openReactivationModal = () => {
  isReactivationModalOpen.value = true
}

const closeReactivationModal = () => {
  reactivationCompleted.value = false
  isReactivationModalOpen.value = false
}
</script>
