import { store } from '@/store'

export default async function (to, from, next) {
  try {
    const adminRoutes = [
      'settings-team-settings',
      'settings-payment-methods',
      'settings-subscriptions',
      'settings-subscription-details',
      'settings-subscriptions-edit-payment',
      'settings-payment-methods',
    ]
    const sessionModule = store.state.session
    if (sessionModule.userRole !== 'admin' && adminRoutes.includes(to.name)) {
      return next({ name: 'settings-profile-settings' })
    }

    return next()
  } catch {
    return next({ name: 'getstarted' })
  }
}
