<template>
  <h4 class="tw-font-bold tw-mt-6 tw-mb-1">{{ $t('business_studio_plus_checkout_payment_details_header') }}</h4>
  <div v-if="isFetching">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-6" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-800">{{ $t('errors.wrong_data_from_app') }}</div>
  <div v-else>
    <div v-if="defaultPaymentMethodType === 'creditCard'" class="tw-inline">
      <img
        v-if="creditCardLogo"
        :src="creditCardLogo"
        alt="Credit card logo"
        height="24"
        width="38"
        class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
      />
      <span>
        {{
          $t('paymentMethods.paymentMethod.creditCard', {
            mask: creditCardNumberMask,
            month: creditCardExpirationMonth,
            year: creditCardExpirationYear,
          })
        }}
        -&nbsp;
      </span>
      <span>{{ creditCardHolderName }}</span>
    </div>
    <div v-else-if="defaultPaymentMethodType === 'SEPA'">
      {{
        $t('paymentMethods.paymentMethod.SEPA', {
          mask: sepaAccountNumberMask,
          account: sepaAccountName,
        })
      }}
    </div>
    <div v-else>-</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { useExistingPaymentMethods } from '@/components/settings/paymentMethods/queries'

const store = useStore()
const currentOrganisation = computed(() => store.state?.auth?.currentOrganisation?.id)

const { defaultPaymentMethod, isError, isFetching } = useExistingPaymentMethods(currentOrganisation)

const sepaAccountName = computed(() => defaultPaymentMethod?.value?.details?.account_name)
const sepaAccountNumberMask = computed(() => defaultPaymentMethod?.value?.details?.account_number_mask)
const creditCardHolderName = computed(() => defaultPaymentMethod?.value?.details?.holder_name)
const creditCardNumberMask = computed(() => defaultPaymentMethod?.value?.details?.mask_number)
const creditCardExpirationMonth = computed(() => defaultPaymentMethod?.value?.details?.expiration_month)
const creditCardExpirationYear = computed(() => defaultPaymentMethod?.value?.details?.expiration_year)

const visaLogo = new URL('/src/assets/img/settings-payment/visa.png', import.meta.url)
const mastercardLogo = new URL('/src/assets/img/settings-payment/mastercard.png', import.meta.url)
const americanexpressLogo = new URL('/src/assets/img/settings-payment/americanexpress.png', import.meta.url)
const creditCardLogo = computed(() => {
  const creditCardBrand = defaultPaymentMethod?.value?.details?.type?.toLowerCase?.()
  if (creditCardBrand === 'visa') return visaLogo
  if (creditCardBrand === 'mastercard') return mastercardLogo
  if (creditCardBrand === 'americanexpress') return americanexpressLogo
  return null
})

const defaultPaymentMethodType = computed(() => {
  if (creditCardNumberMask?.value) return 'creditCard'
  if (sepaAccountNumberMask?.value) return 'SEPA'
  return null
})
</script>
