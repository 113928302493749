<template>
  <div class="tw-mt-6">
    <p>
      {{ $t('subscription_reactivate_modal_text_1') }}
    </p>
    <p
      v-if="type === 'MONTHLY'"
      class="tw-mt-6"
      v-html="
        $t('subscription_reactivate_modal_text_2_monthly', {
          MonthlyPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price / 100)} `,
          PaymentDate: nextPayment,
        })
      "
    />
    <p
      v-if="type !== 'MONTHLY'"
      class="tw-mt-6"
      v-html="
        $t('subscription_reactivate_modal_text_2_annual', {
          AnnualPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price / 100)} `,
          PaymentDate: nextPayment,
        })
      "
    />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  isPositive: Boolean,
  price: Number,
  nextPaymentDate: String,
  type: String,
})

const nextPayment = computed(() => {
  return new Date(props.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale.language)
</script>
