<template>
  <div class="tw-mt-6">
    <p class="tw-font-bold">{{ $t("We're happy to see you again!") }}</p>
    <p
      v-if="type !== 'MONTHLY'"
      class="tw-mt-6"
      v-html="
        $t('subscription_expired_reactivate_modal__annual_text_1', {
          AnnualPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price / 100)} `,
        })
      "
    />
    <p
      v-if="type === 'MONTHLY'"
      class="tw-mt-6"
      v-html="
        $t('subscription_expired_reactivate_modal__monthly_text_1', {
          MonthlyPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price / 100)} `,
        })
      "
    />
  </div>
  <div class="tw-mt-6" v-if="defaultPaymentMethod.expired">
    <p>{{ $t('subscription_expired_reactivate_modal_text_2') }}</p>
  </div>
  <div class="tw-mt-6 tw-rounded-lg tw-border shadow tw-p-5">
    <p class="tw-font-bold">{{ $t('payment_method_confirm_header') }}</p>
    <p class="tw-text-xs">{{ $t('payment_method_confirm_subtext') }}</p>
    <div class="tw-my-6 tw-pb-6 tw-border-b tw-border-gray-300 tw-flex tw-items-center hover:tw-cursor-pointer">
      <div v-if="!defaultPaymentMethod" class="tw-animate-pulse tw-rounded tw-bg-gray-300 tw-h-[35px] tw-w-full" />
      <div v-if="defaultPaymentMethod" class="tw-mr-3 tw-rounded-full tw-h-[24px] tw-w-[24px] tw-inline-flex border" />
      <div v-if="defaultPaymentMethod?.type === 'SEPA'">
        <span class="tw-grow">{{
          `SEPA - ${defaultPaymentMethod.details.account_number_mask} - ${defaultPaymentMethod.details.account_name}`
        }}</span>
      </div>
      <div v-else-if="defaultPaymentMethod?.type === 'CREDIT_CARD'" class="tw-flex tw-gap-x-2 tw-flex-wrap">
        <div>
          <img
            :src="getCreditCardLogoUrl(defaultPaymentMethod.details.type)"
            alt="Credit card logo"
            height="24"
            width="38"
            class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
          />
          <span>{{ defaultPaymentMethod.details.mask_number }}</span>
          <span v-if="defaultPaymentMethod.expired">{{ $t('payment_method_expired') }}</span>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-text-xs tw-items-baseline tw-mt-6" v-if="defaultPaymentMethod">
      <p>
        {{ $t('payment_method_tip') }}
      </p>
      <button class="redirect-btn tw-ml-[2px]" @click="redirectToPaymentPage">{{ $t('click_here') }}</button>
    </div>
  </div>
  <div class="tw-mt-6" v-if="!defaultPaymentMethod.expired">
    <label class="tw-flex tw-justify-start tw-items-center tw-text-xs">
      <input
        :checked="termsAccepted"
        type="checkbox"
        class="hover:tw-cursor-pointer tw-mr-2"
        @change="emit('toggleTerms')"
      />
      <span v-html="$t('business_studio_plus_terms_and_conditions_checkbox')" />
    </label>
  </div>
  <div class="tw-mt-6" v-if="defaultPaymentMethod.expired">
    <p v-html="$t('subscription_expired_reactivate_modal_text_3')" />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const visaLogo = new URL('/src/assets/img/settings-payment/visa.png', import.meta.url)
const mastercardLogo = new URL('/src/assets/img/settings-payment/mastercard.png', import.meta.url)
const americanexpressLogo = new URL('/src/assets/img/settings-payment/americanexpress.png', import.meta.url)

const store = useStore()
const router = useRouter()

const props = defineProps({
  price: Number,
  type: String,
  accommodationId: Number,
  termsAccepted: Boolean,
  defaultPaymentMethod: Object,
  source: String,
})

const emit = defineEmits(['toggleTerms'])
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale.language)
const setReactivationAccommodationId = id => store.commit('studioPlus/SET_REACTIVATION_ACCOMMODATION_ID', id)
const setReactivationSubscriptionType = type => store.commit('studioPlus/SET_REACTIVATION_SUBSCRIPTION_TYPE', type)

const getCreditCardLogoUrl = type => {
  if (type === 'Visa') return visaLogo
  if (type === 'MasterCard') return mastercardLogo
  if (type === 'AmericanExpress') return americanexpressLogo
}

const redirectToPaymentPage = async () => {
  await setReactivationAccommodationId(props.accommodationId)
  await setReactivationSubscriptionType(props.type === 'YEARLY' ? 'annual' : 'monthly')
  router.push({
    name: 'studio-plus-payment',
    query: {
      source: props.source,
    },
  })
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 1px 4px 0px #37454dbf;
}
.border {
  border: 8px solid theme('colors.blue.800');
}
p :deep(a),
span:deep(a),
.redirect-btn {
  text-decoration: underline;
}
</style>
