<template>
  <div>
    <StudioPlusReactivationModal
      v-if="studioPlusCampaign !== null"
      :isOpen="isReactivationOpen"
      :expired="isExpiredSubscription"
      :cancelled="isCancelledSubscription"
      :accommodationId="selectedAccommodationId"
      :accommodationName="currentAccommodation.name"
      source="home"
      @closeModal="toggleReactivateSubscription('close')"
    />
    <StudioPlusUpsellPopup v-if="isAccommodationEligibleForStudioPlus && isAdminUser" />
    <CPAUpsellPopup v-if="showUpsellPopup && !isAccommodationEligibleForStudioPlus && isAdminUser" />

    <NoAccommodationCard v-if="hasNoAccommodations" />
    <div v-else class="tw-flex tw-flex-col tw-py-6">
      <div class="tw-px-4 tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto">
        <CPAUpsellBanner v-if="showUpsell && isAdminUser && !isAccommodationEligibleForStudioPlus" />
        <StudioPlusUpsellBanner
          v-else-if="isStudioPlusDataLoadSuccess && isAccommodationEligibleForStudioPlus && isAdminUser"
        />
        <StudioPlusSubscriptionInactiveBanner
          class="tw-mb-8"
          v-if="isCancelledSubscription && studioPlusCampaign !== null"
          @openReactivationModal="toggleReactivateSubscription('open')"
        />
        <div v-else-if="showStudioPlusSubscriptionBanner">
          <StudioPlusUpdateDataBanner v-if="showUpdateData && showUpdateBanner" @hideBanner="hideUpdateDataBanner" />
          <StudioPlusSubscriptionActiveBanner
            class="tw-mb-8"
            v-if="!isExpiredSubscription && !isCancelledSubscription"
          />
        </div>
        <CarouselContainer v-else class="tw-mb-8" :isStudioPlus="isStudioPlus" :showRateConnect="showRateConnect" />
        <FreeBookingLinkContainer v-if="showFreeLinksSection" class="tw-mb-8" />
        <ProfilePerformanceContainer class="tw-mb-8" />
        <StudioPlusSubscriptionInactiveBanner
          class="tw-mb-8"
          v-if="isExpiredSubscription && studioPlusCampaign !== null && !hotelierData?.hasRateConnect"
          @openReactivationModal="toggleReactivateSubscription('open')"
        />
        <GuestRatingsContainer />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import ProfilePerformanceContainer from '@/components/profilePerformance/MainContainer.vue'
import CarouselContainer from '@/components/dashboard/carousel/CarouselContainer.vue'
import GuestRatingsContainer from '@/components/guestRatings/GuestRatingsContainer.vue'
import NoAccommodationCard from '@/components/dashboard/NoAccommodationCard.vue'
import FreeBookingLinkContainer from '@/components/freeBookingLink/FreeBookingLinkContainer.vue'
import CPAUpsellPopup from '../components/dashboard/rateConnectUpsell/CPAUpsellPopup.vue'
import CPAUpsellBanner from '../components/dashboard/rateConnectUpsell/CPAUpsellBanner.vue'
import StudioPlusUpsellPopup from '@/components/studioPlus/StudioPlusUpsellPopup.vue'
import StudioPlusUpdateDataBanner from '@/components/studioPlus/StudioPlusUpdateDataBanner.vue'
import StudioPlusUpsellBanner from '@/components/dashboard/studioPlusUpsell/StudioPlusUpsellBanner.vue'
import StudioPlusSubscriptionActiveBanner from '@/components/studioPlus/StudioPlusSubscriptionActiveBanner.vue'
import StudioPlusSubscriptionInactiveBanner from '@/components/studioPlus/StudioPlusSubscriptionInactiveBanner.vue'
import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'

import useRateConnect from '@/components/rateConnect/queries'
import { useAllApps } from '@/components/dashboard/nav/queries'

import { ELIGIBLE_COUNTRIES, ELIGIBLE_PARTNERS } from '@/constants/studioPlusEligibility.js'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'

const route = useRoute()
const store = useStore()
const showUpdateBanner = ref(true)
const isReactivationOpen = ref(false)

const toggleReactivateSubscription = action => {
  if (action === 'open') {
    isReactivationOpen.value = true
  }
  if (action === 'close') {
    isReactivationOpen.value = false
  }
}

const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { hotelierData } = useRateConnect(selectedAccommodationId)
const hasNoAccommodations = computed(() => store.state.session.organisations?.length === 0)
const userRole = computed(() => store.state.session?.userRole)
const currency = computed(() => store.state.currency)
const {
  studioPlusCampaign,
  isSuccess: isStudioPlusCampaignSuccess,
  isCancelledSubscription,
  isExpiredSubscription,
} = useStudioPlusCampaign(selectedAccommodationId)
const { studioPlusPrice, isSuccess: isStudioPlusPriceSuccess } = useStudioPlusPrice(selectedAccommodationId)
const { visibleOwnedApps } = useAllApps(selectedAccommodationId)
const showFreeLinksSection = computed(
  () => (hotelierData?.value?.hasFreeLinks || hotelierData?.value?.optInEligible) && studioPlusCampaign.value === null
)
const isStudioPlusDataLoadSuccess = computed(() => isStudioPlusPriceSuccess.value && isStudioPlusCampaignSuccess.value)
const showRateConnect = computed(() => currentAccommodation.value?.countryId !== 8)

const showUpsell = computed(() => {
  if (!isStudioPlusDataLoadSuccess.value) return false
  if (
    hotelierData.value?.hasRateConnect === undefined ||
    hotelierData.value?.hasFreeLinks === undefined ||
    hotelierData.value?.directRatesPartner === undefined
  )
    return false
  if (hotelierData.value?.hasRateConnect === true) return false
  if (hotelierData.value?.hasFreeLinks === false || hotelierData.value?.directRatesPartner === '3250') return false
  if (!isNetCPACampaign.value) return false
  if (studioPlusCampaign.value) return false
  if (!showRateConnect.value) return false
  return true
})

const showUpsellPopup = computed(
  () => showUpsell.value === true && window.sessionStorage?.getItem?.('cpa_upsell_skip') !== 'true'
)

const proApps = [
  import.meta.env.VITE_RATE_INSIGHTS_ID,
  import.meta.env.VITE_VISITOR_PROFILE_ID,
  import.meta.env.VITE_SPECIAL_OFFER_ID,
]
const itemHasPro = computed(() => !!visibleOwnedApps.value?.find(ownedApp => proApps.includes(ownedApp.app_id)))

const isAdminUser = computed(() => userRole.value === 'admin')

const isAccommodationEligibleForStudioPlus = computed(() => {
  if (!isStudioPlusDataLoadSuccess.value) return false
  if (studioPlusCampaign.value) return false
  if (!ELIGIBLE_PARTNERS.includes(hotelierData.value?.directRatesPartner)) return false
  if (hotelierData.value?.hasRateConnect) return false
  if (itemHasPro.value) return false
  if (!studioPlusPrice.value) return false
  if (currency.value !== 'EUR') return false
  if (!ELIGIBLE_COUNTRIES.includes(currentAccommodation.value?.countryId)) return false
  return true
})

const showStudioPlus = computed(() => isAccommodationEligibleForStudioPlus.value && isAdminUser.value)

const showUpdateData = computed(() => {
  const bannerInfo = window.localStorage?.getItem(`contact_info_banner_${selectedAccommodationId.value}`)
  const isBannerVisible = bannerInfo === null ? false : JSON.parse(bannerInfo)
  return isBannerVisible && studioPlusCampaign.value
})

const showStudioPlusSubscriptionBanner = computed(
  () =>
    studioPlusCampaign.value &&
    !(studioPlusCampaign.value?.endDate ? new Date() > new Date(studioPlusCampaign.value?.endDate) : false)
)

const isNetCPACampaign = computed(() => hotelierData.value?.eligibleCampaignTypes?.includes('netCpa'))
const isStudioPlus = computed(() => isAccommodationEligibleForStudioPlus.value || studioPlusCampaign.value !== null)

onMounted(() => {
  if (showStudioPlus.value) {
    const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
    appAnalytics.track(appAnalytics.events.SP_HOMEPAGE_ONBOARDING_VISITED, {
      item_id: selectedAccommodationId.value,
      source: route.query ? route.query : 'SP_Eligable_Homepage',
      traffic_source: trafficSource ?? '',
    })
  }
})

const hideUpdateDataBanner = () => {
  showUpdateBanner.value = false
  window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'false')
  window.dispatchEvent(
    new CustomEvent('contact_info_banner_localstorage-changed', {
      detail: {
        storage: localStorage.getItem(`contact_info_banner_${selectedAccommodationId.value}`),
      },
    })
  )
}
</script>

<style scoped>
@media (max-width: theme('screens.mobile.max')) {
  .tw-min-h-screen {
    min-height: calc(theme('maxHeight.screen') - theme('spacing.16'));
  }
}
</style>
